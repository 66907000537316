import React from 'react';

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block">X4Fare | MobiPix Backoffice &copy; 2022 { process.env.REACT_APP_VERSION ? `| (${process.env.REACT_APP_VERSION})` : '' }</span>
      </footer>
    );
  }
;

export default Footer;
