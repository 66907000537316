import {
  SIGN_OUT,

  GET_ROUTES,
  CREATE_ROUTE,
  UPDATE_ROUTE,

  SHOW_UPDATE_ROUTE_FORM,
  SHOW_CREATE_ROUTE_FORM,
  HIDE_UPDATE_ROUTE_FORM,
  HIDE_CREATE_ROUTE_FORM,
} from "constants/ActionTypes";

const INIT_STATE = {
  routes: {
    data: [],
    count: 0
  },
  routeUpdating: {
    isUpdatingAnRoute: false,
    currentUpdatingRoute: null
  },
  routeCreation: {
    isCreatingAnRoute: false,
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ROUTES: {
      return {
        ...state,
        routes: action.payload,
      };
    }

    case CREATE_ROUTE: {
      const newRoute = action.payload;

      return {
        ...state,
        routes: {
          ...state.routes,
          data: [
            newRoute,
            ...state.routes.data
          ],
          count: state.routes.count + 1
        }
      };
    }

    case UPDATE_ROUTE: {
      const updatedRoute = action.payload;

      const routeIndex = state.routes.data.findIndex(route => route.uuid == updatedRoute.uuid);

      const newRoutes = state.routes.data.map((route, index) => {
        if (index == routeIndex) {
          return updatedRoute;
        }
        return route;
      });

      return {
        ...state,
        routes: {
          ...state.routes,
          data: newRoutes,
        }
      };
    }

    case SHOW_UPDATE_ROUTE_FORM: {
      const routeId = action.payload;
      const currentUpdatingRoute = state.routes.data.find(route => route.uuid == routeId);

      return {
        ...state,
        routeUpdating: {
          isUpdatingAnRoute: true,
          currentUpdatingRoute: currentUpdatingRoute
        },
      };
    }

    case SHOW_CREATE_ROUTE_FORM: {
      return {
        ...state,
        routeCreation: {
          isCreatingAnRoute: true,
        },
      };
    }

    case HIDE_UPDATE_ROUTE_FORM: {
      return {
        ...state,
        routeUpdating: {
          isUpdatingAnRoute: false,
          currentUpdatingRoute: null
        },
      };
    }

    case HIDE_CREATE_ROUTE_FORM: {
      return {
        ...state,
        routeCreation: {
          isCreatingAnRoute: false,
        },
      };
    }

    case SIGN_OUT: {
      return INIT_STATE;
    }

    default: {
      return state;
    }

  }
}
