const formatErrorResponse = error => {

  if (!error?.response?.data) return 'Erro desconhecido durante a operação';

  const { message, fieldErrors, detail, userEmail, entityName, status, violations } = error.response.data;

  switch (message) {
    case 'error.http.500': {
      if (detail === 'email must not be null or empty') {
        return 'E-mail não pode ser nulo';
      }
      if (detail === 'password must be at least 6 characters long') {
        return 'A senha deve possuir no mínimo 6 caracteres';
      }
      if (detail === 'userLogin is null') {
        return 'Erro de validação';
      }
      if (detail === 'Crew is inconsistent. id=50') {
        return 'Motorista inconsistente';
      }
      if (detail === 'Crew not found.') {
        return 'Motorista não encontrado no servidor';
      }
      return 'Erro interno do servidor';
    }

    case 'error.http.400': {
      return 'Erro interno - tente novamente mais tarde';
    }

    case 'error.http.401': {
      if (detail === 'Full authentication is required to access this resource') {
        return 'Usuário não autenticado';
      }
      if (detail === 'Access denied.') {
        return 'Usuário sem permissão para operação realizada';
      }
      if (detail === 'Bad credentials' || detail === 'Usuário inexistente ou senha inválida') {
        return 'Login/Senha incorretos';
      }
      break;
    }

    case 'error.http.403': {
      return 'Usuário sem permissão para operação realizada';
    }

    case 'error.entity.not.found': {
      if (entityName === 'crew') {
        return 'Motorista não encontrado no servidor';
      }
    }

    case 'error.validation': {
      let formattedErrorMessage;
      const invalidFields = new Set();
      if (fieldErrors) {
        fieldErrors.forEach(fieldError => {
          invalidFields.add(fieldError.field);
        });
      }
      if (violations) {
        violations.forEach(fieldError => {
          invalidFields.add(fieldError.field);
        });
      }
      formattedErrorMessage = `Erro de validação no(s) campo(s):`;
      invalidFields.forEach(field => formattedErrorMessage += ` ${formatField(field)}`);
      return formattedErrorMessage;
    }

    case 'firebase.user.already.exists': {
      return `Usuário de e-mail ${userEmail} já cadastrado`;
    }

    case 'error.uuidnull': {
      return 'Uuid inválido';
    }

    case 'error.uuiddexists': {
      return 'Motorista com uuid já existente';
    }

    case 'crew.email.cannot.be.changed': {
      return 'Email de motorista não pode ser alterado';
    }

    default: {
      if (status === 403) {
        return 'Usuário sem permissão para operação realizada';
      }

      return 'Erro desconhecido durante a operação';
    }
  }
}

const formatField = field => {
  switch (field) {
    case 'userEmail':
      return "E-mail";
    default:
      return field;
  }
}

export default formatErrorResponse;
