import {
  SIGN_OUT,
  GET_TRANSACTIONS,
  REQUEST_GET_TRANSACTIONS,
  REQUEST_GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE,
  VOID_TRANSACTION,
  SHOW_VOID_TRANSACTION,
  HIDE_VOID_TRANSACTION,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_ROUTES,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_VEHICLES,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND_FAILURE,
  GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND,
  REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND_FAILURE,
  GET_PAYMENT_STATS,
  REQUEST_GET_PAYMENT_STATS,
  REQUEST_GET_PAYMENT_STATS_FAILURE
} from "../constants/ActionTypes";
import MobipixUtils from 'util/MobipixUtils';
import { PAYMENT_GATEWAY_USER_TYPE } from 'util/MobipixConstants';
import Moment from 'moment';

const INIT_STATE = {
  transactions: { data: [], count: 0 },
  transactionVoid: {
    isVoiding: false,
    voidingTransactionUuid: null
  },
  transactionsCountAndAmountByPguserType: [],
  transactionsCountByPguserTypeAndDay: [],
  transactionAmountByTypeAndDay: [],
  transactionCountByTypeAndDay: [],
  transactionCountByRoutes: [],
  transactionCountByVehicles: [],
  transactionCountByTimeInbound: [],
  transactionCountByTimeOutbound: [],
  paymentStats: [],
  loadingTransactions: false,
  loadingTransactionsError: false,
  loadingTransactionsCountAndAmountByPguserType: false,
  loadingTransactionsCountAndAmountByPguserTypeError: false,
  loadingTransactionsCountByPguserTypeAndDay: false,
  loadingTransactionsCountByPguserTypeAndDayError: false,
  loadingTransactionCountByRoutes: [],
  loadingTransactionCountByRoutesError: [],
  loadingTransactionCountByVehicles: [],
  loadingTransactionCountByVehiclesError: [],
  loadingTransactionCountByTimeInbound: [],
  loadingTransactionCountByTimeInboundError: [],
  loadingTransactionCountByTimeOutbound: [],
  loadingTransactionCountByTimeOutboundError: [],
  loadingPaymentStats: false,
  loadingPaymentStatsError: false
};

export const convertTransactionsCountByPguserTypeAndDayToTwoSeparateArrays = transactionsCountByPguserTypeAndDay => {
  let transactionAmountByTypeAndDay = [];
  let transactionCountByTypeAndDay = [];

  transactionsCountByPguserTypeAndDay.forEach(transactionData => {
    let transactionCountByTypeAndDayItem = { day: transactionData.day };
    let transactionAmountByTypeAndDayItem = { day: transactionData.day };

    for (const field in transactionData) {
      if (PAYMENT_GATEWAY_USER_TYPE.includes(field)) {
        transactionCountByTypeAndDayItem[field] = transactionData[field];

        if (transactionData[field.concat('-amount')]) {
          transactionAmountByTypeAndDayItem[field] = MobipixUtils.convertCentsToRealAndCents(transactionData[field.concat('-amount')]);
        }
      }
    }

    transactionCountByTypeAndDay.push(transactionCountByTypeAndDayItem);
    transactionAmountByTypeAndDay.push(transactionAmountByTypeAndDayItem);

  });

  return [transactionAmountByTypeAndDay, transactionCountByTypeAndDay];
}

export const sortByDateAndFormatDate = arrayWithObjectsWithDateField => {
  if (!arrayWithObjectsWithDateField) {
    return arrayWithObjectsWithDateField;
  }
  return arrayWithObjectsWithDateField
    .map(transationDateByDate => {
      return {
        ...transationDateByDate,
        day: Moment(transationDateByDate.day, ['YYYY-M-D', 'YYYY-MM-DD'])
      }
    })
    .sort((a, b) => a.day - b.day)
    .map(transationDateByDate => {
      return {
        ...transationDateByDate,
        day: transationDateByDate.day.format("DD/MM/YYYY")
      }
    })
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }

    case GET_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload,
        loadingTransactions: false,
        loadingTransactionsError: false
      };
    }

    case REQUEST_GET_TRANSACTIONS: {
      return {
        ...state,
        loadingTransactions: true,
        loadingTransactionsError: false
      }
    }

    case REQUEST_GET_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        loadingTransactions: false,
        loadingTransactionsError: true
      }
    }

    case VOID_TRANSACTION: {
      const { transactionUuid, transactionStatus } = action.payload;

      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: state.transactions.data.map(transaction =>
            transaction?.uuid === transactionUuid ? {...transaction, status: transactionStatus } : transaction
          )
        }
      }
    }

    case SHOW_VOID_TRANSACTION: {
      return {
        ...state,
        transactionVoid: {
          ...state.transactionVoid,
          isVoiding: true,
          voidingTransactionUuid: action.payload
        }
      }
    }

    case HIDE_VOID_TRANSACTION: {
      return {
        ...state,
        transactionVoid: {
          ...state.transactionVoid,
          isVoiding: false,
          voidingTransactionUuid: null
        }
      }
    }

    case GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE: {
      return {
        ...state,
        transactionsCountAndAmountByPguserType: action.payload,
        loadingTransactionsCountAndAmountByPguserType: false,
        loadingTransactionsCountAndAmountByPguserTypeError: false
      };
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE: {
      return {
        ...state,
        loadingTransactionsCountAndAmountByPguserType: true,
        loadingTransactionsCountAndAmountByPguserTypeError: false
      }
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_FAILURE: {
      return {
        ...state,
        loadingTransactionsCountAndAmountByPguserType: false,
        loadingTransactionsCountAndAmountByPguserTypeError: true
      }
    }

    case GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY: {

      let [transactionAmountByTypeAndDay, transactionCountByTypeAndDay] = convertTransactionsCountByPguserTypeAndDayToTwoSeparateArrays(action.payload);

      transactionAmountByTypeAndDay = sortByDateAndFormatDate(transactionAmountByTypeAndDay);
      transactionCountByTypeAndDay = sortByDateAndFormatDate(transactionCountByTypeAndDay);

      const formattedTransactionAmountByTypeAndDay = MobipixUtils.formatListItemsFieldName(transactionAmountByTypeAndDay, MobipixUtils.formatPaymentGatewayUserType);
      const formattedTransactionCountByTypeAndDay = MobipixUtils.formatListItemsFieldName(transactionCountByTypeAndDay, MobipixUtils.formatPaymentGatewayUserType);
      const transactionsCountByPguserTypeAndDay = MobipixUtils.formatListItemsFieldName(action.payload, MobipixUtils.formatPaymentGatewayUserType);

      return {
        ...state,
        transactionAmountByTypeAndDay: formattedTransactionAmountByTypeAndDay,
        transactionCountByTypeAndDay: formattedTransactionCountByTypeAndDay,
        transactionsCountByPguserTypeAndDay: transactionsCountByPguserTypeAndDay,
        loadingTransactionsCountByPguserTypeAndDay: false,
        loadingTransactionsCountByPguserTypeAndDayError: false
      };
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY: {
      return {
        ...state,
        loadingTransactionsCountByPguserTypeAndDay: true,
        loadingTransactionsCountByPguserTypeAndDayError: false
      }
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_PGUSER_TYPE_AND_DAY_FAILURE: {
      return {
        ...state,
        loadingTransactionsCountByPguserTypeAndDay: false,
        loadingTransactionsCountByPguserTypeAndDayError: true
      }
    }

    case GET_TRANSACTIONS_COUNT_BY_ROUTES: {
      return {
        ...state,
        transactionCountByRoutes: action.payload,
        loadingTransactionCountByRoutes: false,
        loadingTransactionCountByRoutesError: false
      };
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES: {
      return {
        ...state,
        loadingTransactionCountByRoutes: true,
        loadingTransactionCountByRoutesError: false
      }
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_ROUTES_FAILURE: {
      return {
        ...state,
        loadingTransactionCountByRoutes: false,
        loadingTransactionCountByRoutesError: true
      }
    }

    case GET_TRANSACTIONS_COUNT_BY_VEHICLES: {
      return {
        ...state,
        transactionCountByVehicles: action.payload,
        loadingTransactionCountByVehicles: false,
        loadingTransactionCountByVehiclesError: false
      };
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES: {
      return {
        ...state,
        loadingTransactionCountByVehicles: true,
        loadingTransactionCountByVehiclesError: false
      }
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_VEHICLES_FAILURE: {
      return {
        ...state,
        loadingTransactionCountByVehicles: false,
        loadingTransactionCountByVehiclesError: true
      }
    }

    case GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND: {
      return {
        ...state,
        transactionCountByTimeInbound: action.payload,
        loadingTransactionCountByTimeInbound: false,
        loadingTransactionCountByTimeInboundError: false
      };
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND: {
      return {
        ...state,
        loadingTransactionCountByTimeInbound: true,
        loadingTransactionCountByTimeInboundError: false
      }
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_INBOUND_FAILURE: {
      return {
        ...state,
        loadingTransactionCountByTimeInbound: false,
        loadingTransactionCountByTimeInboundError: true
      }
    }

    case GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND: {
      return {
        ...state,
        transactionCountByTimeOutbound: action.payload,
        loadingTransactionCountByTimeOutbound: false,
        loadingTransactionCountByTimeOutboundError: false
      };
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND: {
      return {
        ...state,
        loadingTransactionCountByTimeOutbound: true,
        loadingTransactionCountByTimeOutboundError: false
      }
    }

    case REQUEST_GET_TRANSACTIONS_COUNT_BY_TIME_OUTBOUND_FAILURE: {
      return {
        ...state,
        loadingTransactionCountByTimeOutbound: false,
        loadingTransactionCountByTimeOutboundError: true
      }
    }

    case GET_PAYMENT_STATS: {
      return {
        ...state,
        paymentStats: action.payload,
        loadingPaymentStats: false,
        loadingPaymentStatsError: false
      };
    }

    case REQUEST_GET_PAYMENT_STATS: {
      return {
        ...state,
        loadingPaymentStats: true,
        loadingPaymentStatsError: false
      }
    }

    case REQUEST_GET_PAYMENT_STATS_FAILURE: {
      return {
        ...state,
        loadingPaymentStats: false,
        loadingPaymentStatsError: true
      }
    }

    default: {
      return state;
    }
  }
}
